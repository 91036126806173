.display-grid {
  display: grid;
}
.onboarding {
  position: relative;
  display: inline-block;
  list-style-position: inside;
  margin-left: -25px;
}
@media (min-width: 768px) {
  .onboarding.split-list {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }
}
.onboarding .sellable {
  background-color: var(--panel-background-color);
}
.ct-line {
  stroke-width: 2px;
}
@media (min-width: 1200px) {
  .ct-line {
    stroke-width: 3px;
  }
}
.ct-point {
  stroke-width: 6px;
}
@media (min-width: 1200px) {
  .ct-point {
    stroke-width: 9px;
  }
}
@media (min-width: 1200px) {
  .ct-bar {
    stroke-width: 15px;
  }
}
.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar {
  stroke: var(--body-color);
}
.ct-chart > .ct-chart-line,
.ct-chart > .ct-chart-bar {
  margin-top: 10px;
  overflow: visible;
}
.ct-label {
  color: var(--body-color);
  font-size: 11.2px;
}
@media (min-width: 1200px) {
  .ct-label {
    font-size: 13.6px;
    line-height: 1.68;
  }
}
.ct-grid {
  stroke: var(--grey-color);
}
.ct-legend {
  position: relative;
  z-index: 10;
  list-style: none;
  text-align: center;
}
.ct-legend li {
  position: relative;
  padding-left: 23px;
  margin-right: 20px;
  margin-bottom: 3px;
  display: inline-block;
}
.ct-legend li:before {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 0;
  content: '';
  border: 3px solid transparent;
  border-radius: 2px;
}
.ct-legend li.inactive:before {
  background: transparent;
}
.ct-legend.ct-legend-inside {
  position: absolute;
  top: 0;
  right: 0;
}
.ct-chart .ct-series-0:before {
  background-color: var(--body-color);
  border-color: var(--body-color);
}
.line .ct-label.ct-horizontal {
  position: relative;
  transform: translateX(-25%);
  transform-origin: left top;
  width: 80px !important;
}
