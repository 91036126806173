#particles {
  position: absolute;
  width: 100%;
  height: 100vh;
}
#auth-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#auth-page #auth-block img {
  max-height: 100px;
  max-width: 200px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
#auth-page #auth-block .panel-default i {
  vertical-align: initial;
}
#auth-page #auth-block .panel-body {
  padding: 30px;
}
@media (min-width: 768px) {
  #auth-page #auth-block {
    width: 600px;
  }
}
.input-group {
  display: flow-root;
}
