.center-horizontal {
  width: 95%;
}
@media (min-width: 768px) {
  .center-horizontal {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .center-horizontal {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .center-horizontal {
    width: 70%;
  }
}
.strap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.strap img {
  margin-bottom: 20px;
}
.strap h1,
.strap .h1 {
  font-size: 62px;
  font-weight: 800;
  color: white;
  letter-spacing: -3px;
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}
.strap h5,
.strap .h5 {
  font-weight: 600;
  color: white;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) {
  .strap h1,
  .strap .h1 {
    font-size: 80px;
  }
  .strap h5,
  .strap .h5 {
    font-size: 24px;
  }
}
.intro {
  margin: -115px auto 20px auto;
}
.retail {
  width: 95%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .retail {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .retail {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .retail {
    width: 70%;
  }
}
.retail h1,
.retail h2,
.retail h3,
.retail h4,
.retail h5,
.retail h6,
.retail .h1,
.retail .h2,
.retail .h3,
.retail .h4,
.retail .h5,
.retail .h6 {
  font-weight: 600;
}
.retail h1,
.retail .h1 {
  margin: 10px 50px 10px 0;
}
.angular-google-map-container {
  height: 500px;
}
.accreditation {
  display: flex;
  justify-content: center;
}
.accreditation img {
  max-height: 150px;
}
